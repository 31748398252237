import React, { useContext, useEffect, useState } from "react"
import Img from "gatsby-image"
import Hero from "~/components/configurable/Hero"
import Heading from "~/components/configurable/Heading"
import Block from "../configurable/Block"
import Col8 from "../grid/Col8"
import Col6 from "../grid/Col6"
import Col4 from "../grid/Col4"
import Expandable from "../configurable/Expandable"
import Icon from "../olc-framework/Icon"
import BlockCTA from "../configurable/BlockCTA"
import TickList from "../configurable/TickList"
import { imageNodesFilter, markdownNodesFilter } from "~/utils"
import { CustomerTypeContext } from "~/providers/CustomerTypeProvider"
import Col11 from "../grid/Col11"
import Image from "../configurable/Image"
import Col12 from "../grid/Col12"
import Col9 from "../grid/Col9"
import Grid from "../configurable/Grid"
import "../configurable/ProductsAndWarrantiesBlock/styles.scss"
import Section from "../configurable/Section"





const IS_HALLOWEEN = true;

const bothlogos = require('../../images/2buy2.jpg');

const centerstyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
};

const TwoBuyTwo = ({ markdownNodes, imageNodes }) => {
  const [heroImage, setHeroImage] = useState(undefined)
  const [loading, setLoading] = useState(true)
  const { setCustomerType } = useContext(CustomerTypeContext)

  useEffect(() => {
    setCustomerType("solartogether")

    setHeroImage(imageNodesFilter(imageNodes, "churches.png"))

    setLoading(false)
  }, [imageNodes])


  const productsBlockRef = React.createRef() as React.RefObject<HTMLElement>

  const [filter,] = React.useState<string>('Twobuytwo');

  const productsWarranties = markdownNodesFilter(
    markdownNodes,
    "products_and_warranties"
  )

  const filteredProducts = React.useMemo(() => {
    if (filter === '') {
      return productsWarranties;
    }
    return productsWarranties.filter((product) => {
      return product.frontmatter.category === filter;
    });
  }, [productsWarranties, filter]);

  return (
    <div className="homepage" style={{ opacity: loading ? 0 : 1 }}>

<Hero imageUrl="/images/banner_new_2.png" compact>
        <Heading level={1} underlined>
        Parish and Church Buying Scheme
        </Heading>
      </Hero>
      <Block>
        <div className="twobuytwo">
          <Section> 
            <div className="container container--column illustrated-house-block">
              <Heading underlined>Let there be light</Heading>
              <p>
                Would you like to power your church with energy courtesy of the sun, all the while providing a good example for your congregation?
              </p>
              <p>
                Solar PV systems provide huge potential for more than energy savings. Be a leading example to your local community with a visible statement that shows your fellowship cares about God's creation and taking action to have a positive impact on climate change.
              </p>
              <div className="container u-layout--indent">
                <div className="row" >
                  <Col11 style={centerstyle}>
                    <img src={bothlogos} />
                  </Col11>
                </div>
              </div>
            </div>
          </Section>
          <Section>        
            <div className="row">
              <Col12>
                <Heading level={2}>Can your church run on solar?</Heading>
                <p>
                  Whether it is a small community center or a large listed building, there’s a renewable energy option for you. Green Energy Together is proud to share our partnership with 2buy2, providing specialized solar installation services for churches and community buildings across the UK. <br/>
                </p>
              </Col12>
              <Col8
                style={{
                  borderTop: "1px solid #d1d1d1",
                  paddingTop: 52,
                }}
              >
                <Icon alias="solar-fitting" style={{ fontSize: 90, color: "#051c3f", minWidth: "70px", marginBottom: "15px", marginTop: "30px" }} />
                <Heading level={4}>Church Buying and 2buy2</Heading>
                <p>
                  <a href="https://www.churchbuying.org.uk/about" style={{ color: '#70b33b', fontWeight: 'bold' }}>
                    Church Buying</a>  is a purchasing service provided by 2buy2, a procurement business committed to protecting the work of the Church. Since 2009 Church Buying has been a trusted adviser and provider of procurement support to faith organisations, <a href="https://www.2buy2.com/news/14-news/51-the-early-days-of-2buy2" style={{ color: '#70b33b', fontWeight: 'bold' }}>
                    enabling millions</a> to be invested in the mission and ministry of the Church rather than wasted in overpriced deals.
                </p>
                <p>
                  Church Buying helps churches and others reduce time and money spent on day-to-day purchases and long-term planning needs.
                </p>
              </Col8>
              <Col4>
                <div style={{ marginBottom: "50px", marginTop: "70px" }}>
                  <Image
                    src={require('../../images/paddington.png')}
                    title="Installation at Paddington Station"
                    caption="​"
                  />
                  <BlockCTA secondary url="/project/paddington-station/" arrow="right">
                    See case study
                  </BlockCTA>
                </div>
              </Col4>
            </div>
          </Section>
          <Section>  
            <div className="row">
              <Col9>
                <Heading level={2}>Bespoke design for your place of worship</Heading>
              </Col9>
              <Col8 style={{ marginBottom: 120 }}>
                <p>The installation of any solar PV systems on a historical building should be carried out taking into consideration not only the energy efficiency of the whole building, but also the impact on the heritage impact to the building. A holistic approach enables the design of a system that provides the ideal balance between energy savings and protecting the heritage significance.
                </p>
                <p>High-quality design is key when planning any solar PV installation and in order to achieve this a sound understanding of the energy usage, location, character and historical importance of the building are needed to produce the most effective solution.
                </p>
                <p>The location will have an effect on what kind of microgeneration you choose: it will greatly influence what type of renewable energy source is appropriate. Issues such as the amount of sunlight reaching the roof slopes or whether there is sufficient space around the building to install a ground source heat pump will need to be considered.</p><p>
                  Given the rapidity with which renewable energy technologies are evolving, renewable energy projects and their associated infrastructure should aim to be reversible where possible.
                </p>
              </Col8>
              <div style={{ marginTop:20, padding:15 }} >
                <Image src={require('../../images/stjohnschurch.png')} title="stjohnsstreet" caption="Cross shape design for St John's Church by Green Energy Together  " />
              </div>
              
              <Col8 >
                <h3 style={{marginTop:40}}>System types</h3>
                <p>
                  Optimisation is an extra feature you can pay for when buying solar that optimises the power output from each panel independently.

                  Most people don’t realise that in a conventional solar power system if one panel is performing poorly, all the other modules it shares a ‘string’ with will be dragged down too. </p><p>

                  At Green Energy Together we offer a variety of optimised and non optimised options, including the innovative technology of <a href="https://get-uk.com/static/df0417b8100ae5dd5e502267d4fdb6e9/get-solar-edge-power-optimizer.pdf" style={{ color: '#70b33b', fontWeight: 'bold' }}> Solar Edge
                  </a> which takes optimisation to the next level by allowing each panel to communicate directly with the inverter.
                </p>
              </Col8>
            
              <Col8>
                <h3 style={{ marginTop: "30px" }}>Installation Options</h3>
                <p>
                  There are many different types of Solar PV installation to suit all applications, depending on the roof type and heritage of the place of worship, with the most common listed below:
                </p>
                <ul>
                  <li><strong style={{ color: '#70b33b' }}> • Roof-Mounted</strong> – Retrofit for pitched</li>
                  <li><strong style={{ color: '#70b33b' }}> • Flat roofs</strong> – Aluminium frame weighted down with ballast or custom built clamps.</li>
                  <li><strong style={{ color: '#70b33b' }}> • In roof systems</strong> - Flush mounted for Slate and tiled roofs.</li>
                  <li><strong style={{ color: '#70b33b' }}> • Solar Tiles</strong> – Slate look roof tiling with solar PV technology</li>
                  <li><strong style={{ color: '#70b33b' }}> • Free standing ground mounts</strong> – Installed on ground away from the church.</li>
                  <li><strong style={{ color: '#70b33b' }}> • Solar Car Parks</strong> – Car Park shelters with Solar PV roofs and EV charge points.</li>
                </ul>
            </Col8>
            </div>
            <div className="row" style={{ marginTop:40 }}>
              <Col4>
                  <div style={{ marginBottom: "30px" }}>
                  <Image src={require('../../images/flatroof.png')} title="Flat roof installation" caption="Flat roof installation" />
                  </div>
                  <div style={{ marginBottom: "30px" }}>
                  <Image src={require('../../images/retrofit.png')} title="Retrofit installation" caption="Retrofit installation" />
                  </div>
              </Col4>
              <Col4>
                  <div style={{ marginBottom: "30px" }}>
                  <Image src={require('../../images/solartiles.png')} title="Installation using solar tiles" caption="Installation using solar tiles" />
                  </div>
                  <div style={{ marginBottom: "30px" }}>
                  <Image src={require('../../images/groundmount.png')} title="Ground-mounted installation" caption="Ground-mounted installation" />
                  </div>
              </Col4>
            </div>
          </Section>          
          <Section>
            <div className="row">
              <Col8 >
                <Heading level={3} >Solar in your parish</Heading>
                <p >
                  Generally, permission is required for works which are ‘development’ and broadly is given either after a formal application is made, or if it is considered to be ‘permitted development’ and can be carried out subject to compliance with any conditions or limitations set out.</p><p>
                  If the proposals do not fall within the permitted development (perhaps because it is larger than what is permitted, or there are implications for the historic environment (listed buildings, conservation areas, scheduled monuments etc) or the ability to rely on permitted development has been removed) then an application for planning permission will be required.</p><p>
                  You can find out more about planning permission and permitted development rights by visiting the <a href="https://www.planningportal.co.uk/" style={{ color: '#70b33b', fontWeight: 'bold' }}>
                    UK Planning Portal's website.</a>
                </p>
                <Expandable
                  readmore={
                    <>
                      <div className="visible-xs" style={{ height: 40 }} />
                      <div className="row">
                        <Col6 className="u-layout--centered">
                          <div
                            className="icon__circle-wrapper"
                            style={{ marginBottom: 40, fontSize: 40, paddingTop: 20 }}
                          >
                            <Icon alias="solar-house" />
                          </div>
                          <h3 >Listed building consent</h3>
                          <p>

                            Listed building consent is required for the change to a listed building in any manner which would affect its character as a building of special architectural or historic interest. </p><p>The requirement applies to all types of works, and to all parts of buildings covered by the listing protection (possibly including attached and curtilage buildings or other structures).
                          </p>
                        </Col6>
                        <Col6 className="u-layout--centered">
                          <div
                            className="icon__circle-wrapper"
                            style={{ marginBottom: 40, fontSize: 40, paddingTop: 20 }}
                          >
                            <Icon alias="solar-power" />
                          </div>
                          <h3 >Places of worship</h3>
                          <p>
                            You may need to get permission from the relevant denominational advisory committee or listed building consent. If your denomination is one of those covered by the Ecclesiastical Exemption, you will need consent from the advisory committee. The Ecclesiastical Exemption does not remove the need for planning permission. If your denomination is not exempt, you will need listed building consent and planning permission as set out above.
                          </p>
                        </Col6>
                      </div>
                    </>
                  }
                />
              </Col8>
              <Col4>
                <div style={{
                  marginBottom: "30px", marginTop: 60
                }}>
                  <Image src={require('../../images/church4.jpg')} title="Church with solar panels in cross shape" caption="Church with solar panels in cross shape" />
                </div>
              </Col4>
            </div>
          </Section>
          <Section>
            <div className="row" >
              <Col8
                style={{
                  borderTop: "1px solid #d1d1d1",
                  paddingTop: 52,
                }}
              >
                <Icon alias="solar-energy" style={{ fontSize: 90, color: "#051c3f", minWidth: "70px", marginBottom: "15px"}} />
                <Heading level={4}>Green Energy Together</Heading>
                <p>
                  <a href="https://get-uk.com/about-us/" style={{ color: '#70b33b', fontWeight: 'bold' }}> Green Energy Together
                  </a> are a leading solar panels installer with over 10 years’ experience in the UK and over 400MW of solar installed. We are in support of Net Zero 2030.
                </p><p>
                  We offer bespoke solar solutions for all market sectors and our team has installed over 40KW+ systems in British properties including and a number of historic British landmarks, such as <a href="https://get-uk.com/project/kings-college-hospital/" style={{ color: '#70b33b', fontWeight: 'bold' }}>
                    King's College Hospital </a> and <a href="https://get-uk.com/project/paddington-station/" style={{ color: '#70b33b', fontWeight: 'bold' }}>
                    Paddington Train Station </a>.
                </p>
              </Col8>
              <Col4>
                <div >
                  <Image
                    src={require('../../images/king.png')}
                    title="Installation at King's College Hospital"
                    caption="​"
                  />
                  <BlockCTA secondary url="/project/kings-college-hospital/" arrow="right">
                    See case study
                  </BlockCTA>
                </div>
              </Col4>
            </div>
          </Section>
          <Section>
            <div >
              <Heading level={2} >Get started</Heading>
              <p>
                We’ll take you through the process of going solar in 7 easy steps.
              </p>
              <div className="container u-layout--indent">
                <div className="row" style={{ marginTop: "20px", marginBottom: "70px" }} >
                  <Col9 style={centerstyle}>
                    <img src={'../../images/process.jpg'} />
                  </Col9>
                </div>
              </div>
              <Col9>
                <p>
                  Installing a renewable energy system can have a major impact on your church building so it’s important to think about how best to reduce that impact.

                  Given the speed at which technologies are evolving it is also important to make sure that installations are reversible where possible. </p><p>

                  You will probably need the following supporting information for your faculty application:
                </p>
                <p>
                  <ul>
                    <li><strong style={{ color: '#70b33b' }}> • </strong>An introductory statement providing background information and setting the work in context</li>
                    <li><strong style={{ color: '#70b33b' }}> • </strong>Statement of needs for the work</li>
                    <li><strong style={{ color: '#70b33b' }}> • </strong>Statement of significance for the building or building remains affected by the work</li>
                    <li><strong style={{ color: '#70b33b' }}> • </strong>Description of the proposed work and drawings</li>
                    <li><strong style={{ color: '#70b33b' }}> • </strong>Technical specifications (e.g. installation fixings, cable runs, maintenance requirements, etc)</li>
                    <li><strong style={{ color: '#70b33b' }}> • </strong>An impact statement by your architect or archaeologist</li>
                    <li><strong style={{ color: '#70b33b' }}> • </strong>Your Diocesan Advisory Committee secretary will be able to give you advice”</li>
                    <li><strong style={{ color: '#70b33b' }}> • </strong>Robust engineering statement based on appropriate calculations (e.g. for roof-mounted solar panel installations, the weight of the array and its housing, wind loading and dynamic stresses, etc)</li>
                  </ul></p>
                <p>
                  To learn more just register your interest with 2buy2 and a member of the team will call you shortly to discuss and arrange your free remote survey.
                  Alternatively, call us now on <a href="tel:07824438740" style={{ color: '#70b33b', fontWeight: 'bold' }}> 078 2443 8740
                  </a> or email <a href="mailto:commercial.enquiries@get-uk.com" style={{ color: '#70b33b', fontWeight: 'bold' }}> commercial.enquiries@get-uk.com
                  </a>.
                </p>
              </Col9>
            </div>
          </Section>
          <Section>
            <Col9>
              <div >
                <Heading level={2} >Useful links</Heading>
                <p>
                  We have compiled a collection of links that may be useful for your solar journey.
                </p>
                <TickList>
                  <li>
                    <a target="_blank"
                      style={{ color: "#3c96c5", fontWeight: "normal", }}
                      href="https://www.parishbuying.org.uk/">
                      Parish Buying Landing Page
                    </a>
                  </li>
                  <li>
                    <a target="_blank"
                      style={{ color: "#3c96c5", fontWeight: "normal", }}
                      href="https://www.churchofengland.org/resources/churchcare/advice-and-guidance-church-buildings/renewable-energy">
                      Renewable energy | The Church of England
                    </a>
                  </li>
                  <li>
                    <a target="_blank"
                      style={{ color: "#3c96c5", fontWeight: "normal", }}
                      href="https://www.churchofengland.org/about/environment-and-climate-change/diocesan-environmental-officers-map">
                      Diocesan Environmental Officers map | The Church of England
                    </a>
                  </li>
                  <li>
                    <a target="_blank"
                      style={{ color: "#3c96c5", fontWeight: "normal", }}
                      href="https://www.churchofengland.org/about/environment-and-climate-change/webinars-getting-net-zero-carbon">
                      Webinars on getting to net zero carbon | The Church of England
                    </a>
                  </li>
                  <li>
                    <a target="_blank"
                      style={{ color: "#3c96c5", fontWeight: "normal", }}
                      href="https://facultyonline.churchofengland.org/renewables">
                      Map showing current use of renewable energy sources in churches | The Church of England
                    </a>
                  </li>
                  <li>
                    <a target="_blank"
                      style={{ color: "#3c96c5", fontWeight: "normal", }}
                      href="https://www.churchbuying.org.uk/">
                      Church Buying website
                    </a>
                  </li>
                  <li>
                    <a target="_blank"
                      style={{ color: "#3c96c5", fontWeight: "normal", }}
                      href="https://www.churchbuying.org.uk/news">
                      Church Buying news and insights
                    </a>
                  </li>
                </TickList>
              </div>
            </Col9>
          </Section>
        </div>
      </Block>

      {!!productsWarranties?.length && (
        <Block>
          <div className="container" >
            <div
              className="row"

              ref={productsBlockRef}
            >
              <Heading level={2}>Potential Product Range</Heading>
              <p>
                For further information about the products provided under the scheme, visit out Products page or get in touch with one of our
                advisors.
              </p>


              <Grid>
                {filteredProducts.map(item => {
                  const pwItem = item.frontmatter

                  return (
                    <li key={item.fields.slug}>
                      {pwItem.image?.childImageSharp && (
                        <Img
                          fluid={pwItem.image.childImageSharp.fluid}
                          alt={pwItem.title}
                        />
                      )}
                      <div
                        style={{
                          display: "flex",
                          alignItems: "top",
                          marginTop: 24,
                        }}
                      >
                        <p className="grid-item__title">{pwItem.title}</p>
                        {pwItem.pdf?.publicURL && (
                          <>
                            <a
                              href={pwItem.pdf.publicURL}
                              target="_blank"
                              style={{
                                display: "block",
                                marginTop: 0,
                                marginLeft: 24,
                              }}
                            >
                              <div
                                className="icon__circle-wrapper"
                                style={{ fontSize: 32, color: "#70b33b" }}
                              >

                              </div>
                            </a>
                          </>
                        )}
                      </div>
                    </li>
                  )
                })}
              </Grid>
            </div>
          </div>
        </Block>
      )}


    </div>
  )
}

export default TwoBuyTwo